<template>
  <svg
    width="auto"
    height="auto"
    viewBox="0 0 12 14"
    fill="#D9D9D9"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.99935 0.333496C3.19935 0.333496
    0.666016 2.48016 0.666016 5.80016C0.666016 7.92016 2.29935 10.4135 5.55935 13.2868C5.81268
    13.5068 6.19268 13.5068 6.44602 13.2868C9.69935 10.4135 11.3327 7.92016 11.3327
    5.80016C11.3327 2.48016 8.79935 0.333496 5.99935 0.333496ZM5.99935 7.66683C7.10392
    7.66683 7.99935 6.7714 7.99935 5.66683C7.99935 4.56226 7.10392 3.66683 5.99935
    3.66683C4.89478 3.66683 3.99935 4.56226 3.99935 5.66683C3.99935 6.7714 4.89478
    7.66683 5.99935 7.66683Z"
    />
  </svg>
</template>
