<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="#212121"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.82 10.173L11.1267 9.97968C10.72 9.93301 10.32 10.073 10.0334
    10.3597L8.80669 11.5863C6.92003 10.6263 5.37336 9.08635 4.41336 7.19301L5.64669
    5.95968C5.93336 5.67301 6.07336 5.27301 6.02669 4.86635L5.83336 3.18635C5.75336
    2.51301 5.18669 2.00635 4.50669 2.00635H3.35336C2.60003 2.00635 1.97336 2.63301
    2.02003 3.38635C2.37336 9.07968 6.92669 13.6263 12.6134 13.9797C13.3667 14.0263
    13.9934 13.3997 13.9934 12.6463V11.493C14 10.8197 13.4934 10.253 12.82 10.173Z"
    />
  </svg>
</template>
