<template>
  <svg
    width="39"
    height="20"
    viewBox="0 0 39 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.50065 10.833C5.27565 10.833 0.833984
    11.9497 0.833984 14.1663V15.833C0.833984 16.2913 1.20898 16.6663 1.66732
    16.6663H13.334C13.7923 16.6663 14.1673 16.2913 14.1673 15.833V14.1663C14.1673
    11.9497 9.72565 10.833 7.50065 10.833Z"
      fill="#212121"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.5006 9.99967C14.3423 9.99967
    15.8339 8.50801 15.8339 6.66634C15.8339 4.82467 14.3423 3.33301 12.5006 3.33301C12.1089
    3.33301 11.7423 3.41634 11.3923 3.53301C12.0839 4.39134 12.5006 5.48301 12.5006
    6.66634C12.5006 7.84967 12.0839 8.94134 11.3923 9.79967C11.7423 9.91634 12.1089
    9.99967 12.5006 9.99967Z"
      fill="#212121"
    />
    <path
      d="M7.50065 9.99967C9.3416 9.99967 10.834 8.50729 10.834 6.66634C10.834
    4.82539 9.3416 3.33301 7.50065 3.33301C5.6597 3.33301 4.16732 4.82539 4.16732
    6.66634C4.16732 8.50729 5.6597 9.99967 7.50065 9.99967Z"
      fill="#212121"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.8923 10.9421C15.0339 11.7171
    15.8339 12.7671 15.8339 14.1671V16.6671H18.3339C18.7923 16.6671 19.1673 16.2921
    19.1673 15.8337V14.1671C19.1673 12.3504 16.1923 11.2754 13.8923 10.9421Z"
      fill="#212121"
    />
    <path
      d="M22.868 15V14.209V13.796C22.868 13.278 22.9637 12.83 23.155 12.452C23.351
    12.074 23.6077 11.745 23.925 11.465C24.247 11.1803 24.597 10.926 24.975 10.702C25.3577
    10.478 25.738 10.2633 26.116 10.058C26.4987 9.848 26.8487 9.631 27.166 9.407C27.4833
    9.17833 27.7377 8.92167 27.929 8.637C28.125 8.34767 28.223 8.007 28.223 7.615C28.223
    7.10167 28.041 6.684 27.677 6.362C27.3177 6.04 26.83 5.879 26.214 5.879C25.5513 5.879
    25.0193 6.06567 24.618 6.439C24.2167 6.81233 23.9903 7.30933 23.939 7.93H22.651C22.665
    7.34667 22.8097 6.81467 23.085 6.334C23.3603 5.84867 23.764 5.46133 24.296 5.172C24.828
    4.88267 25.4837 4.738 26.263 4.738C26.9397 4.738 27.5277 4.85933 28.027 5.102C28.5263
    5.34 28.9113 5.676 29.182 6.11C29.4573 6.53933 29.595 7.041 29.595 7.615C29.595 8.13767
    29.4993 8.58567 29.308 8.959C29.1167 9.33233 28.8623 9.65667 28.545 9.932C28.2323 10.2027
    27.8893 10.4453 27.516 10.66C27.1427 10.8747 26.767 11.0823 26.389 11.283C26.011 11.479
    25.6657 11.6867 25.353 11.906C25.0403 12.1207 24.7883 12.368 24.597 12.648C24.4057 12.928
    24.31 13.2617 24.31 13.649V13.852H29.651V15H22.868ZM37.7948 15H36.3878L34.2808 12.165L32.1878
    15H30.7668L33.5598 11.241L30.9908 7.762H32.4048L34.2808 10.303L36.1638 7.762H37.5778L35.0018
    11.241L37.7948 15Z"
      fill="#212121"
    />
  </svg>
</template>
