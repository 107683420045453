<template>
  <svg
    width="70"
    height="70"
    viewBox="0 0 70 70"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.5">
      <path
        d="M34.9935 13.125C20.4102 13.125 7.95599 22.1958 2.91016 35C7.95599 47.8042 20.4102
      56.875 34.9935 56.875C49.5768 56.875 62.031 47.8042 67.0768 35C62.031 22.1958 49.5768
      13.125 34.9935 13.125ZM34.9935 49.5833C26.9435 49.5833 20.4102 43.05 20.4102 35C20.4102
      26.95 26.9435 20.4167 34.9935 20.4167C43.0435 20.4167 49.5768 26.95 49.5768 35C49.5768
      43.05 43.0435 49.5833 34.9935 49.5833ZM34.9935 26.25C30.1518 26.25 26.2435 30.1583
      26.2435 35C26.2435 39.8417 30.1518 43.75 34.9935 43.75C39.8352 43.75 43.7435 39.8417
      43.7435 35C43.7435 30.1583 39.8352 26.25 34.9935 26.25Z"
        fill="white"
      />
    </g>
  </svg>
</template>
