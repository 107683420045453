<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="42"
    height="39"
    viewBox="0 0 42 39"
  >
    <g
      fill="none"
      fill-rule="evenodd"
      transform="rotate(180 21 19.5)"
    >
      <path
        fill="#E6F4FA"
        d="M28.9693613,12.1008289 C29.469759,12.0343251 29.9803124,12 30.49887,12 C36.8505945,12 42,17.1462684 42,23.4983049 C42,29.8514715 36.8505945,35 30.49887,35 C28.7713689,35 27.132699,34.6188942 25.6623971,33.9361096 C23.0026635,37.029828 18.9895771,39 14.5,39 C6.49201637,39 0,32.7318463 0,25 C0,21.5922247 1.26111616,18.4687811 3.35731092,16.0410159 C3.1237079,15.0657103 3,14.0476205 3,13.0005581 C3,5.82056235 8.81882969,0 16.0005581,0 C22.8787115,0 28.5073872,5.34054674 28.9693613,12.1008289 Z"
      />
      <path
        fill="#FFFFFF"
        d="M25.7129699,14.9431541 C26.8536129,14.3396855 28.1395044,14 29.4988916,14 C34.186139,14 38,18.0366482 38,23.0005869 C38,27.9633518 34.186139,32 29.4988916,32 C27.2986953,32 25.2910292,31.1101569 23.7801166,29.6524095 C21.8873669,33.4138935 17.9900082,36 13.5,36 C7.15899729,36 2,30.8421581 2,24.5 C2,21.1954233 3.40113857,18.211869 5.64051942,16.1122333 C5.22624883,14.9852652 5,13.7682536 5,12.4994255 C5,6.70968977 9.71083876,2 15.5005745,2 C21.2903102,2 26,6.70968977 26,12.4994255 C26,13.3404802 25.9006144,14.1587676 25.7129699,14.9431541 Z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'CloudIcon',
};
</script>
