<template>
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="#666666"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M25.3327 10.6667H6.66602C4.45268 10.6667 2.66602 12.4533 2.66602 14.6667V20C2.66602
      21.4667 3.86602 22.6667 5.33268 22.6667H7.99935V25.3333C7.99935 26.8 9.19935 28 10.666
      28H21.3327C22.7994 28 23.9993 26.8 23.9993 25.3333V22.6667H26.666C28.1327 22.6667
      29.3327 21.4667 29.3327 20V14.6667C29.3327 12.4533 27.546 10.6667 25.3327
      10.6667ZM19.9993 25.3333H11.9993C11.266 25.3333 10.666 24.7333 10.666
      24V18.6667H21.3327V24C21.3327 24.7333 20.7327 25.3333 19.9993 25.3333ZM25.3327
      16C24.5993 16 23.9993 15.4 23.9993 14.6667C23.9993 13.9333 24.5993 13.3333 25.3327
      13.3333C26.066 13.3333 26.666 13.9333 26.666 14.6667C26.666 15.4 26.066 16 25.3327
      16ZM22.666 4H9.33268C8.59935 4 7.99935 4.6 7.99935 5.33333V8C7.99935 8.73333 8.59935
      9.33333 9.33268 9.33333H22.666C23.3993 9.33333 23.9993 8.73333 23.9993
      8V5.33333C23.9993 4.6 23.3993 4 22.666 4Z"
    />
  </svg>
</template>
