<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="85"
    height="96"
    viewBox="0 0 85 96"
  >
    <g
      fill="none"
      fill-rule="evenodd"
    >
      <path
        fill="#E6F4FA"
        d="M53.6261275,86.0983539 C44.9724207,86.0904138 37.961165,79.0475196 37.961165,70.367114 C37.961165,70.2821461 37.9618371,70.1973351 37.9631762,70.1126861 C36.052893,71.3237206 33.7893915,72.0245902 31.3626805,72.0245902 C24.5242637,72.0245902 18.9805825,66.4646135 18.9805825,59.6056903 C18.9805825,58.7095146 19.07527,57.8354956 19.2551602,56.9931488 C14.9861648,52.3470271 12.3786408,46.1411762 12.3786408,39.3246282 C12.3786408,38.980572 12.3852821,38.6380723 12.3984431,38.2972509 C5.24955652,36.3134881 0,29.739649 0,21.9367926 C0,12.565248 7.57527256,4.96721311 16.9183389,4.96721311 C21.1033698,4.96721311 24.9339252,6.4922497 27.8876267,9.01856689 C30.937945,3.63274285 36.7085736,0 43.3269645,0 C53.1237687,0 61.0679612,7.96813226 61.0679612,17.7983167 C61.0679612,20.170505 60.6055033,22.4341966 59.7661462,24.5036533 C62.6686866,28.7123217 64.368932,33.8192168 64.368932,39.3246282 C64.368932,45.434588 62.2742784,51.0538958 58.7658771,55.4991083 C60.2757998,56.0228961 61.6811612,56.771633 62.9421284,57.7053251 C65.1528918,56.2866361 67.7623944,55.4672131 70.5574066,55.4672131 C78.5348335,55.4672131 85,62.139615 85,70.3688525 C85,76.9889463 80.8160275,82.6015244 75.0266219,84.5433647 C75.0411625,84.7726494 75.0485437,85.0038839 75.0485437,85.2368457 C75.0485437,91.1795229 70.2454545,96 64.3203883,96 C58.686077,96 54.0638162,91.6384942 53.6261275,86.0983539 Z"
      />
      <path
        fill="#FFFFFF"
        d="M56.3512418,80.989288 C54.9373334,81.5501071 53.3974426,81.8584071 51.787234,81.8584071 C44.9235834,81.8584071 39.3404255,76.2595622 39.3404255,69.3787797 C39.3404255,65.7762562 40.8716505,62.5253142 43.3161178,60.2457302 C41.9299403,60.6282602 40.4913511,60.8826894 39.0138682,60.9954556 C37.949601,64.8651574 34.4071226,67.7168142 30.2136372,67.7168142 C25.17979,67.7168142 21.0851064,63.6105553 21.0851064,58.5663717 C21.0851064,57.3014675 21.3425925,56.0955429 21.8078058,54.9985589 C17.2845467,50.8157531 14.4468085,44.8270972 14.4468085,38.1875636 C14.4468085,36.9014589 14.5532259,35.6397985 14.7576931,34.4109662 C7.64263685,33.9287895 2,27.9735101 2,20.7168142 C2,13.147399 8.14226892,6.99115044 15.6906203,6.99115044 C20.9926227,6.99115044 25.6003637,10.0270806 27.8742627,14.4563702 C28.8932077,7.42179944 34.9496383,2 42.2455469,2 C50.2497002,2 56.7659574,8.53117508 56.7659574,16.5575221 C56.7659574,19.1456372 56.088719,21.5779972 54.9027194,23.6860433 C58.1397867,27.6360187 60.0851064,32.6883322 60.0851064,38.1875636 C60.0851064,45.9744285 56.1825466,52.8660327 50.2345104,56.9992306 C50.7432832,56.9354932 51.2615023,56.9026549 51.787234,56.9026549 C55.4417448,56.9026549 58.7340461,58.490221 61.0133791,61.0134991 C63.0311172,58.9844895 65.7783823,57.7345133 68.8012587,57.7345133 C74.9763131,57.7345133 80,62.9562954 80,69.3787704 C80,75.2436145 75.8131,80.107763 70.385595,80.9103885 C70.6865252,81.721561 70.8510638,82.5988391 70.8510638,83.5141368 C70.8510638,87.6399858 67.4994155,91 63.383839,91 C59.2631009,91 55.9148936,87.6399858 55.9148936,83.5141368 C55.9148936,82.6284372 56.0688047,81.7783382 56.3512418,80.989288 Z"
      />
    </g>
  </svg>
</template>
