<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#212121"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15 19.9996V12.3496C15 12.0696 14.88 11.7996 14.67 11.6096L9.67 7.10961C9.48 6.92961
    9.24 6.84961 9 6.84961C8.76 6.84961 8.52 6.93961 8.33 7.10961L3.33 11.6096C3.12 11.7896 3
    12.0596 3 12.3496V19.9996C3 20.5496 3.45 20.9996 4 20.9996H6C6.55 20.9996 7 20.5496 7
    19.9996V15.9996H11V19.9996C11 20.5496 11.45 20.9996 12 20.9996H14C14.55 20.9996 15
    20.5496 15 19.9996Z"
    />
    <path
      d="M20 3H12C11.45 3 11 3.45 11 4V5.61C11 5.61 11 5.61 11.01 5.62L16.01
    10.12C16.64 10.68 17 11.5 17 12.35V13H19V15H17V17H19V19H17V21H20C20.55 21 21 20.55 21
    20V4C21 3.45 20.55 3 20 3ZM15 7H13V5H15V7ZM19 11H17V9H19V11ZM19 7H17V5H19V7Z"
    />
  </svg>
</template>
