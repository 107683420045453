<template>
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="#444444"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.082 7.58329H2.91536C2.59453 7.58329
    2.33203 7.32079 2.33203 6.99996C2.33203 6.67913 2.59453 6.41663 2.91536 6.41663H11.082C11.4029
    6.41663 11.6654 6.67913 11.6654 6.99996C11.6654 7.32079 11.4029 7.58329 11.082 7.58329Z"
    />
  </svg>
</template>
