<template>
  <div>
    <svg
      width="auto"
      height="auto"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.50065 10.8334C5.27565 10.8334 0.833984 11.95 0.833984 14.1667V15.8334C0.833984 16.2917 1.20898 16.6667 1.66732 16.6667H13.334C13.7923 16.6667 14.1673 16.2917 14.1673 15.8334V14.1667C14.1673 11.95 9.72565 10.8334 7.50065 10.8334Z"
        fill="#212121"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.5006 10C14.3423 10 15.8339 8.50837 15.8339 6.66671C15.8339 4.82504 14.3423 3.33337 12.5006 3.33337C12.1089 3.33337 11.7423 3.41671 11.3923 3.53337C12.0839 4.39171 12.5006 5.48337 12.5006 6.66671C12.5006 7.85004 12.0839 8.94171 11.3923 9.80004C11.7423 9.91671 12.1089 10 12.5006 10Z"
        fill="#212121"
      />
      <path
        d="M7.50065 10C9.3416 10 10.834 8.50766 10.834 6.66671C10.834 4.82576 9.3416 3.33337 7.50065 3.33337C5.6597 3.33337 4.16732 4.82576 4.16732 6.66671C4.16732 8.50766 5.6597 10 7.50065 10Z"
        fill="#212121"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.8923 10.9424C15.0339 11.7174 15.8339 12.7674 15.8339 14.1674V16.6674H18.3339C18.7923 16.6674 19.1673 16.2924 19.1673 15.8341V14.1674C19.1673 12.3508 16.1923 11.2758 13.8923 10.9424Z"
        fill="#212121"
      />
    </svg>
  </div>
</template>
