<template>
  <svg width="26" height="23" viewBox="0 0 26 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.83396 16C9.10063 16 8.50063 16.6 8.50063 17.3333V22.6667H4.50063C3.76729 22.6667 3.16729 22.0667 3.16729 21.3333V16.44L2.66062 16.9467C2.14062 17.4667 1.30063 17.4667 0.780625 16.9467C0.260625 16.4267 0.260625 15.5867 0.780625 15.0667L8.90063 6.94667C9.42063 6.42667 10.2606 6.42667 10.7806 6.94667L18.9006 15.0667C19.4206 15.5867 19.4206 16.4267 18.9006 16.9467C18.3806 17.4667 17.5406 17.4667 17.0206 16.9467L16.5006 16.44V21.3333C16.5006 22.0667 15.9006 22.6667 15.1673 22.6667H11.1673V17.3333C11.1673 16.6 10.5673 16 9.83396 16Z"
      fill="#666666"
    />
    <path
      d="M25.0721 6.09524H21.2626V9.90476C21.2626 10.3238 20.9197 10.6667 20.5007 10.6667C20.0816 10.6667 19.7387 10.3238 19.7387 9.90476V6.09524H15.9292C15.5102 6.09524 15.1673 5.75238 15.1673 5.33333C15.1673 4.91429 15.5102 4.57143 15.9292 4.57143H19.7387V0.761905C19.7387 0.342857 20.0816 0 20.5007 0C20.9197 0 21.2626 0.342857 21.2626 0.761905V4.57143H25.0721C25.4911 4.57143 25.834 4.91429 25.834 5.33333C25.834 5.75238 25.4911 6.09524 25.0721 6.09524Z"
      fill="#666666"
    />
  </svg>
</template>
