// eslint-disable-next-line
export enum WidgetTypeE {
  SEARCH = 'search',
  MATRIX = 'matrix',
  MATRIX_SEARCH = 'matrixSearch',
}

// eslint-disable-next-line no-shadow
export enum PaymentOptionsE {
  PAY_LATER = 'Pay later',
  PAY_PART = 'Pay 82% now',
  PAY_FULL = 'Pay 100% now'
}

// eslint-disable-next-line no-shadow
export enum MatrixModeE {
  AVAILABLE_TEXT = 'availableText',
  RATES = 'rates',
  AVAILABILITY = 'availability'
}

// eslint-disable-next-line no-shadow
export enum CaseE {
  SENTENCE_CASE = 'sentence',
  UPPER_CASE = 'upper',
  LOWER_CASE = 'lower',
  TITLE_CASE = 'title',
}

// eslint-disable-next-line no-shadow
export enum ElementE {
  BUTTON = 'button',
  TITLE = 'title',
  SUBTITLE = 'subtitle',
  DROPDOWN = 'dropdown',
  LABEL = 'label',
  INPUT = 'input',
  PLACEHOLDER = 'placeholder',
}
