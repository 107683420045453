<template>
  <div>
    <svg
      width="auto"
      height="auto"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="100%"
        height="100%"
        rx="4"
        fill="#F6F6F6"
      />
      <path
        d="M13.6192 10.6665L12.6392 7.43984C12.4458 6.80651 11.5525 6.80651 11.3658 7.43984L10.3792 10.6665H7.41249C6.76583 10.6665 6.49916 11.4998 7.02583 11.8732L9.45249 13.6065L8.49916 16.6798C8.30583 17.2998 9.02583 17.7998 9.53916 17.4065L11.9992 15.5398L14.4592 17.4132C14.9725 17.8065 15.6925 17.3065 15.4992 16.6865L14.5458 13.6132L16.9725 11.8798C17.4992 11.4998 17.2325 10.6732 16.5858 10.6732H13.6192V10.6665Z"
        fill="#212121"
      />
    </svg>
  </div>
</template>
