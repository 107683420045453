<template>
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="#D9D9D9"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.99398 0.333496C3.31398 0.333496 0.333984 3.32016 0.333984 7.00016C0.333984
    10.6802 3.31398 13.6668 6.99398 13.6668C10.6807 13.6668 13.6673 10.6802 13.6673
    7.00016C13.6673 3.32016 10.6807 0.333496 6.99398 0.333496ZM11.614 4.3335H9.64732C9.43398
    3.50016 9.12732 2.70016 8.72732 1.96016C9.95399 2.38016 10.974 3.2335 11.614
    4.3335ZM7.00065 1.6935C7.55398 2.4935 7.98732 3.38016 8.27398 4.3335H5.72732C6.01398
    3.38016 6.44732 2.4935 7.00065 1.6935ZM1.84065 8.3335C1.73398 7.90683 1.66732 7.46016
    1.66732 7.00016C1.66732 6.54016 1.73398 6.0935 1.84065 5.66683H4.09398C4.04065 6.10683
    4.00065 6.54683 4.00065 7.00016C4.00065 7.4535 4.04065 7.8935 4.09398 8.3335H1.84065ZM2.38732
    9.66683H4.35398C4.56732 10.5002 4.87398 11.3002 5.27398 12.0402C4.04732 11.6202 3.02732
    10.7735 2.38732 9.66683ZM4.35398 4.3335H2.38732C3.02732 3.22683 4.04732 2.38016 5.27398
    1.96016C4.87398 2.70016 4.56732 3.50016 4.35398 4.3335ZM7.00065 12.3068C6.44732 11.5068
    6.01398 10.6202 5.72732 9.66683H8.27398C7.98732 10.6202 7.55398 11.5068 7.00065
    12.3068ZM8.56065 8.3335H5.44065C5.38065 7.8935 5.33398 7.4535 5.33398 7.00016C5.33398
    6.54683 5.38065 6.10016 5.44065 5.66683H8.56065C8.62065 6.10016 8.66732 6.54683 8.66732
    7.00016C8.66732 7.4535 8.62065 7.8935 8.56065 8.3335ZM8.72732 12.0402C9.12732 11.3002
    9.43398 10.5002 9.64732 9.66683H11.614C10.974 10.7668 9.95399 11.6202 8.72732
    12.0402ZM9.90732 8.3335C9.96065 7.8935 10.0007 7.4535 10.0007 7.00016C10.0007 6.54683
    9.96065 6.10683 9.90732 5.66683H12.1607C12.2673 6.0935 12.334 6.54016 12.334
    7.00016C12.334 7.46016 12.2673 7.90683 12.1607 8.3335H9.90732Z"
    />
  </svg>
</template>
