<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="138"
    height="130"
    viewBox="0 0 138 130"
  >
    <g
      fill="none"
      fill-rule="evenodd"
    >
      <path
        fill="#FFFFFF"
        d="M18.359375 53.6070313C20.5734375 53.6070313 22.3664062 55.403125 22.3664062 57.6164063L22.3664062 62.9601563C22.3664062 65.175 20.5734375 66.96875 18.359375 66.96875 16.146875 66.96875 14.3515625 65.175 14.3515625 62.9601563L14.3515625 57.6164063C14.3515625 55.403125 16.146875 53.6070313 18.359375 53.6070313M39.8648438 44.196875C42.0789063 44.196875 43.875 45.9921875 43.875 48.2054687L43.875 53.5492187C43.875 55.7640625 42.0789063 57.5585937 39.8648438 57.5585937 37.6507813 57.5585937 35.85625 55.7640625 35.85625 53.5492187L35.85625 48.2054687C35.85625 45.9921875 37.6507813 44.196875 39.8648438 44.196875"
      />
      <polyline
        fill="#FFA600"
        points="64.623 108.155 46.487 119.975 48.025 127.793 68.184 121.874 64.623 108.155"
      />
      <polyline
        fill="#FFA600"
        points="73.452 108.155 91.591 119.975 90.051 127.793 69.894 121.874 73.452 108.155"
      />
      <polyline
        fill="#F4F4F4"
        points=".25 77.402 64.288 72.034 64.288 39.112 .25 70.188 .25 77.402"
      />
      <path
        fill="#FFFFFF"
        d="M119.640625 53.6070313C117.425781 53.6070313 115.632031 55.403125 115.632031 57.6164063L115.632031 62.9601563C115.632031 65.175 117.425781 66.96875 119.640625 66.96875 121.853125 66.96875 123.65 65.175 123.65 62.9601563L123.65 57.6164063C123.65 55.403125 121.853125 53.6070313 119.640625 53.6070313M98.1351563 44.196875C95.9210938 44.196875 94.1257813 45.9921875 94.1257813 48.2054687L94.1257813 53.5492187C94.1257813 55.7640625 95.9210938 57.5585937 98.1351563 57.5585937 100.349219 57.5585937 102.142188 55.7640625 102.142188 53.5492187L102.142188 48.2054687C102.142188 45.9921875 100.349219 44.196875 98.1351563 44.196875"
      />
      <polyline
        fill="#F4F4F4"
        points="137.749 77.402 73.712 72.034 73.712 39.112 137.749 70.188 137.749 77.402"
      />
      <path
        fill="#FFFFFF"
        d="M72.1414063,2.090625 C71.34375,1.03046875 70.1992188,0.42734375 69.003125,0.43984375 C67.8070313,0.45390625 66.6726563,1.0796875 65.8914063,2.15859375 L65.8882813,2.1640625 C60.40625,9.75 57.4132813,19.3789062 57.4132813,29.3828125 L57.4132813,61.08125 C57.4132813,67.9523437 58.0390625,82.8390625 59.2617188,90.259375 C61.3546875,102.939062 63.5164063,115.017969 67.040625,127.711719 C67.3460938,128.816406 68.140625,129.550781 69.03125,129.560937 C69.925,129.56875 70.7273438,128.849219 71.046875,127.751562 C74.678125,115.230469 76.9359375,102.877344 78.9453125,89.9078125 C80.0414063,82.8445312 80.5859375,68.4710937 80.5859375,61.9492187 L80.5859375,28.3929687 C80.5859375,18.6890625 77.5828125,9.3203125 72.1414063,2.090625"
      />
      <path
        fill="#E4E4E4"
        d="M69.0429688,0.43984375 L69,0.43984375 L69,129.558594 L69.05,129.558594 C69.9351563,129.558594 70.73125,128.841406 71.046875,127.751562 C74.678125,115.230469 76.9359375,102.877344 78.9453125,89.9078125 C80.0414062,82.8445312 80.5859375,68.4710937 80.5859375,61.9492187 L80.5859375,28.3929687 C80.5859375,18.6890625 77.5828125,9.3203125 72.1414063,2.090625 C71.3523438,1.04140625 70.2265625,0.43984375 69.0429688,0.43984375"
      />
      <path
        fill="#00AFDD"
        d="M68.6726563,9.840625 C64.709375,9.840625 61.496875,13.0523438 61.496875,17.0148438 C61.496875,17.2414063 61.6507813,17.440625 61.8710938,17.4992188 C62.0898438,17.5570313 62.321875,17.4585938 62.4335938,17.2617188 C63.6648438,15.0875 65.9953125,13.6195313 68.6726563,13.6195313 C71.35,13.6195313 73.6804688,15.0875 74.9109375,17.2625 C75.0234375,17.4601563 75.2546875,17.5585938 75.4742188,17.5 C75.69375,17.44375 75.8484375,17.2445313 75.8484375,17.0179688 L75.8484375,17.0164063 C75.8484375,13.0523438 72.6351563,9.840625 68.6726563,9.840625"
      />
      <path
        fill="#FFFFFF"
        d="M68.6726563,9.840625 L68.6726563,13.6179688 C71.3515625,13.6179688 73.68125,15.0875 74.9109375,17.2617188 C75.003125,17.4226563 75.1703125,17.5164063 75.3476563,17.5164063 L75.4757813,17.4992188 C75.6953125,17.4421875 75.85,17.2429688 75.85,17.0148438 C75.85,13.0523438 72.6367188,9.840625 68.6726563,9.840625"
        opacity=".152"
      />
      <polygon
        fill="#FFA600"
        points="67.755 121.461 70.242 121.461 70.242 105.548 67.755 105.548"
      />
      <polygon
        fill="#FFA600"
        points="69 121.461 70.242 121.461 70.242 105.548 69 105.548"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'PlaneIcon'
};
</script>
