<template>
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="#666666"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.2496 0.758126C10.9246 0.433126 10.3996 0.433126 10.0746 0.758126L5.99961
    4.82479L1.92461 0.749792C1.59961 0.424792 1.07461 0.424792 0.749609 0.749792C0.424609
    1.07479 0.424609 1.59979 0.749609 1.92479L4.82461 5.99979L0.749609 10.0748C0.424609
    10.3998 0.424609 10.9248 0.749609 11.2498C1.07461 11.5748 1.59961 11.5748 1.92461
    11.2498L5.99961 7.17479L10.0746 11.2498C10.3996 11.5748 10.9246 11.5748 11.2496
    11.2498C11.5746 10.9248 11.5746 10.3998 11.2496 10.0748L7.17461 5.99979L11.2496
    1.92479C11.5663 1.60813 11.5663 1.07479 11.2496 0.758126Z"
    />
  </svg>
</template>
