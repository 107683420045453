<template>
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.075 10.5H5.925C5.7825 10.5 5.685 10.65 5.7525 10.7775C6.375 11.955 7.5975 12.75 9 12.75C10.4025 12.75 11.625 11.955 12.2475 10.7775C12.3075 10.65 12.21 10.5 12.075 10.5ZM17.205 8.505C17.0175 7.3725 16.185 6.45 15.0975 6.1275C14.7 5.2875 14.1375 4.5525 13.455 3.945C12.27 2.8875 10.71 2.25 9 2.25C7.29 2.25 5.73 2.8875 4.545 3.945C3.855 4.5525 3.2925 5.295 2.9025 6.1275C1.815 6.45 0.9825 7.365 0.795 8.505C0.765 8.6625 0.75 8.8275 0.75 9C0.75 9.1725 0.765 9.3375 0.795 9.495C0.9825 10.6275 1.815 11.55 2.9025 11.8725C3.2925 12.705 3.855 13.44 4.53 14.04C5.715 15.105 7.2825 15.75 9 15.75C10.7175 15.75 12.285 15.105 13.4775 14.04C14.1525 13.44 14.715 12.6975 15.105 11.8725C16.185 11.55 17.0175 10.635 17.205 9.495C17.235 9.3375 17.25 9.1725 17.25 9C17.25 8.8275 17.235 8.6625 17.205 8.505ZM14.25 10.5C14.175 10.5 14.1075 10.485 14.0325 10.4775C13.8825 10.98 13.665 11.445 13.3875 11.8725C12.45 13.305 10.8375 14.25 9 14.25C7.1625 14.25 5.55 13.305 4.6125 11.8725C4.335 11.445 4.1175 10.98 3.9675 10.4775C3.8925 10.485 3.825 10.5 3.75 10.5C2.925 10.5 2.25 9.825 2.25 9C2.25 8.175 2.925 7.5 3.75 7.5C3.825 7.5 3.8925 7.515 3.9675 7.5225C4.1175 7.02 4.335 6.555 4.6125 6.1275C5.55 4.695 7.1625 3.75 9 3.75C10.8375 3.75 12.45 4.695 13.3875 6.1275C13.665 6.555 13.8825 7.02 14.0325 7.5225C14.1075 7.515 14.175 7.5 14.25 7.5C15.075 7.5 15.75 8.175 15.75 9C15.75 9.825 15.075 10.5 14.25 10.5Z"
      fill="#212121"
    />
    <path
      d="M7.125 8.8125C7.64277 8.8125 8.0625 8.39277 8.0625 7.875C8.0625 7.35723 7.64277 6.9375 7.125 6.9375C6.60723 6.9375 6.1875 7.35723 6.1875 7.875C6.1875 8.39277 6.60723 8.8125 7.125 8.8125Z"
      fill="#212121"
    />
    <path
      d="M10.875 8.8125C11.3928 8.8125 11.8125 8.39277 11.8125 7.875C11.8125 7.35723 11.3928 6.9375 10.875 6.9375C10.3572 6.9375 9.9375 7.35723 9.9375 7.875C9.9375 8.39277 10.3572 8.8125 10.875 8.8125Z"
      fill="#212121"
    />
  </svg>
</template>
