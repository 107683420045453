import { defineCustomElement } from 'vue';
import App from './App.ce.vue';

interface TCEComponent extends Record<string, any> {
  styles?: string[];
  components?: Record<string, TCEComponent>;
}

const getChildrenComponentsStyles = (component: TCEComponent) => {
  let componentStyles: string[] = [];
  if (component.components) {
    componentStyles = Object.values(component.components).reduce<string[]>((styles, nested) => {
      if (nested?.components) {
        styles.push(...getChildrenComponentsStyles(nested));
      }

      if (nested.styles) {
        styles.push(...nested.styles);
      }

      return styles;
    }, []);
  }
  if (component.styles) {
    componentStyles.push(...component.styles);
  }

  return [...new Set(componentStyles)];
};

const styles = getChildrenComponentsStyles(App);

function defineUniqueCustomElement(elementName: string, constructorFunction: CustomElementConstructor) {
  if (!customElements.get(elementName)) {
    customElements.define(elementName, constructorFunction);
  }
}

const loadFont = (family: string) => {
  const head = document.head || document.getElementsByTagName('head')[0];
  const linkGooglEapis = document.createElement('link');
  const linkGStatic = document.createElement('link');
  const linkFont = document.createElement('link');

  linkGooglEapis.rel = 'preconnect';
  linkGStatic.rel = 'preconnect';
  linkGStatic.crossOrigin = 'crossorigin';
  linkFont.rel = 'stylesheet';

  head.append(linkGooglEapis, linkGStatic, linkFont);

  linkGooglEapis.href = 'https://fonts.googleapis.com';
  linkGStatic.href = 'https://fonts.gstatic.com';
  linkFont.href = `https://fonts.googleapis.com/css2?family=${family}:wght@100;200;300;400;500;600;700;800;900&display=swap`;
};

loadFont('Inter');

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const MyVueElement = defineCustomElement({ ...App, styles });

defineUniqueCustomElement('rr-resnova', MyVueElement);
