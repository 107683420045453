<template>
  <div>
    <svg
      width="auto"
      height="auto"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.9175 18.3336C12.9759 18.3336 13.0342 18.3336 13.0925 18.3336C13.7925 18.3169 14.0259 17.4086 13.4342 17.0336C11.1176 15.5586 9.57588 12.9586 9.57588 10.0086C9.57588 7.05024 11.1176 4.45857 13.4342 2.97524C14.0175 2.60024 13.8009 1.6919 13.1092 1.67524C13.0759 1.67524 13.0342 1.67524 13.0009 1.67524C8.91755 1.63357 5.32588 4.6169 4.68422 8.65024C3.86755 13.8419 7.87588 18.3336 12.9175 18.3336Z"
        fill="#212121"
      />
    </svg>
  </div>
</template>
