<template>
  <svg
    width="auto"
    height="auto"
    viewBox="0 0 12 12"
    fill="#D9D9D9"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.5 10.0833H2.5C2.17917 10.0833 1.91667 9.82083 1.91667 9.5V2.5C1.91667
    2.17917 2.17917 1.91667 2.5 1.91667H5.41667C5.7375 1.91667 6 1.65417 6 1.33333C6
    1.0125 5.7375 0.75 5.41667 0.75H1.91667C1.26917 0.75 0.75 1.275 0.75 1.91667V10.0833C0.75
    10.725 1.275 11.25 1.91667 11.25H10.0833C10.725 11.25 11.25 10.725 11.25 10.0833V6.58333C11.25
    6.2625 10.9875 6 10.6667 6C10.3458 6 10.0833 6.2625 10.0833 6.58333V9.5C10.0833 9.82083
    9.82083 10.0833 9.5 10.0833ZM7.16667 1.33333C7.16667 1.65417 7.42917 1.91667 7.75
    1.91667H9.26083L3.935 7.2425C3.7075 7.47 3.7075 7.8375 3.935 8.065C4.1625 8.2925 4.53
    8.2925 4.7575 8.065L10.0833 2.73917V4.25C10.0833 4.57083 10.3458 4.83333 10.6667
    4.83333C10.9875 4.83333 11.25 4.57083 11.25 4.25V1.33333C11.25 1.0125 10.9875 0.75
    10.6667 0.75H7.75C7.42917 0.75 7.16667 1.0125 7.16667 1.33333Z"
    />
  </svg>
</template>
