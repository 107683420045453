<template>
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.75 9.75C4.7475 9.75 0.75 10.755 0.75 12.75V14.25C0.75 14.6625 1.0875 15 1.5 15H12C12.4125 15 12.75 14.6625 12.75 14.25V12.75C12.75 10.755 8.7525 9.75 6.75 9.75Z"
      fill="#212121"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.2499 9C12.9074 9 14.2499 7.6575 14.2499 6C14.2499 4.3425 12.9074 3 11.2499 3C10.8974 3 10.5674 3.075 10.2524 3.18C10.8749 3.9525 11.2499 4.935 11.2499 6C11.2499 7.065 10.8749 8.0475 10.2524 8.82C10.5674 8.925 10.8974 9 11.2499 9Z"
      fill="#212121"
    />
    <path
      d="M6.75 9C8.40685 9 9.75 7.65685 9.75 6C9.75 4.34315 8.40685 3 6.75 3C5.09315 3 3.75 4.34315 3.75 6C3.75 7.65685 5.09315 9 6.75 9Z"
      fill="#212121"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.5024 9.84814C13.5299 10.5456 14.2499 11.4906 14.2499 12.7506V15.0006H16.4999C16.9124 15.0006 17.2499 14.6631 17.2499 14.2506V12.7506C17.2499 11.1156 14.5724 10.1481 12.5024 9.84814Z"
      fill="#212121"
    />
  </svg>
</template>
