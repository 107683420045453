<template>
  <div>
    <svg
      width="auto"
      height="auto"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.3157 11.1491C9.70877 11.1491 9.21219 11.6457 9.21219 12.2526V16.6667H5.90161C5.29468 16.6667 4.79809 16.1701 4.79809 15.5632V11.5132L4.37875 11.9326C3.94838 12.363 3.25316 12.363 2.82278 11.9326C2.39241 11.5022 2.39241 10.807 2.82278 10.3766L9.54324 3.65615C9.97362 3.22578 10.6688 3.22578 11.0992 3.65615L17.8197 10.3766C18.25 10.807 18.25 11.5022 17.8197 11.9326C17.3893 12.363 16.6941 12.363 16.2637 11.9326L15.8333 11.5132V15.5632C15.8333 16.1701 15.3367 16.6667 14.7298 16.6667H11.4192V12.2526C11.4192 11.6457 10.9226 11.1491 10.3157 11.1491Z"
        fill="#212121"
      />
    </svg>
  </div>
</template>
