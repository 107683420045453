<template>
  <svg
    width="36"
    height="20"
    viewBox="0 0 36 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.0007 9.99967C11.8423 9.99967 13.334 8.50801 13.334 6.66634C13.334 4.82467
    11.8423 3.33301 10.0007 3.33301C8.15898 3.33301 6.66732 4.82467 6.66732 6.66634C6.66732
    8.50801 8.15898 9.99967 10.0007 9.99967ZM10.0007 11.6663C7.77565 11.6663 3.33398 12.783
    3.33398 14.9997V15.833C3.33398 16.2913 3.70898 16.6663 4.16732 16.6663H15.834C16.2923
    16.6663 16.6673 16.2913 16.6673 15.833V14.9997C16.6673 12.783 12.2257 11.6663 10.0007
    11.6663Z"
      fill="#212121"
    />
    <path
      d="M24.604 15V6.943H22.651V6.096C23.2577 6.096 23.729 6.05167 24.065 5.963C24.401
    5.87433 24.639 5.739 24.779 5.557C24.9237 5.375 25.003 5.14867 25.017
    4.878H25.941V15H24.604ZM35.1562 15H33.7492L31.6422 12.165L29.5492 15H28.1282L30.9212
    11.241L28.3522 7.762H29.7662L31.6422 10.303L33.5252 7.762H34.9392L32.3632 11.241L35.1562 15Z"
      fill="#212121"
    />
  </svg>
</template>
