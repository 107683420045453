<template>
  <div>
    <svg
      width="auto"
      height="auto"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.4173 11.6667H6.58398C6.42565 11.6667 6.31732 11.8333 6.39232 11.975C7.08398 13.2833 8.44232 14.1667 10.0007 14.1667C11.559 14.1667 12.9173 13.2833 13.609 11.975C13.6756 11.8333 13.5673 11.6667 13.4173 11.6667ZM19.1173 9.45C18.909 8.19167 17.984 7.16667 16.7756 6.80833C16.334 5.875 15.709 5.05833 14.9507 4.38333C13.634 3.20833 11.9007 2.5 10.0007 2.5C8.10065 2.5 6.36732 3.20833 5.05065 4.38333C4.28398 5.05833 3.65898 5.88333 3.22565 6.80833C2.01732 7.16667 1.09232 8.18333 0.883984 9.45C0.850651 9.625 0.833984 9.80833 0.833984 10C0.833984 10.1917 0.850651 10.375 0.883984 10.55C1.09232 11.8083 2.01732 12.8333 3.22565 13.1917C3.65898 14.1167 4.28398 14.9333 5.03398 15.6C6.35065 16.7833 8.09232 17.5 10.0007 17.5C11.909 17.5 13.6506 16.7833 14.9756 15.6C15.7256 14.9333 16.3506 14.1083 16.784 13.1917C17.984 12.8333 18.909 11.8167 19.1173 10.55C19.1507 10.375 19.1673 10.1917 19.1673 10C19.1673 9.80833 19.1507 9.625 19.1173 9.45ZM15.834 11.6667C15.7506 11.6667 15.6756 11.65 15.5923 11.6417C15.4256 12.2 15.184 12.7167 14.8757 13.1917C13.834 14.7833 12.0423 15.8333 10.0007 15.8333C7.95898 15.8333 6.16732 14.7833 5.12565 13.1917C4.81732 12.7167 4.57565 12.2 4.40898 11.6417C4.32565 11.65 4.25065 11.6667 4.16732 11.6667C3.25065 11.6667 2.50065 10.9167 2.50065 10C2.50065 9.08333 3.25065 8.33333 4.16732 8.33333C4.25065 8.33333 4.32565 8.35 4.40898 8.35833C4.57565 7.8 4.81732 7.28333 5.12565 6.80833C6.16732 5.21667 7.95898 4.16667 10.0007 4.16667C12.0423 4.16667 13.834 5.21667 14.8757 6.80833C15.184 7.28333 15.4256 7.8 15.5923 8.35833C15.6756 8.35 15.7506 8.33333 15.834 8.33333C16.7506 8.33333 17.5006 9.08333 17.5006 10C17.5006 10.9167 16.7506 11.6667 15.834 11.6667Z"
        fill="#212121"
      />
      <path
        d="M7.91732 9.79167C8.49261 9.79167 8.95898 9.3253 8.95898 8.75C8.95898 8.1747 8.49261 7.70833 7.91732 7.70833C7.34202 7.70833 6.87565 8.1747 6.87565 8.75C6.87565 9.3253 7.34202 9.79167 7.91732 9.79167Z"
        fill="#212121"
      />
      <path
        d="M12.084 9.79167C12.6593 9.79167 13.1256 9.3253 13.1256 8.75C13.1256 8.1747 12.6593 7.70833 12.084 7.70833C11.5087 7.70833 11.0423 8.1747 11.0423 8.75C11.0423 9.3253 11.5087 9.79167 12.084 9.79167Z"
        fill="#212121"
      />
    </svg>
  </div>
</template>
