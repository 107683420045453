<template>
  <svg
    width="9"
    height="6"
    viewBox="0 0 9 6"
    fill="white"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.11451 1.06342C0.849852 1.32807 0.849852 1.75559 1.11451 2.02024L4.22927
    5.13501C4.49392 5.39966 4.92144 5.39966 5.18609 5.13501L8.30086 2.02024C8.56551
    1.75559 8.56551 1.32807 8.30086 1.06342C8.03621 0.798765 7.60869 0.798765 7.34404
    1.06342L4.70429 3.69638L2.07133 1.06342C1.80668 0.798765 1.37237 0.805551 1.11451
    1.06342Z"
    />
  </svg>
</template>
