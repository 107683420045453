<template>
  <div>
    <svg
      width="auto"
      height="auto"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.2403 13.333L17.2803 6.87969C16.8936 5.61302 15.1069 5.61302 14.7336 6.87969L12.7603 13.333H6.82694C5.5336 13.333 5.00027 14.9997 6.0536 15.7464L10.9069 19.213L9.00027 25.3597C8.6136 26.5997 10.0536 27.5997 11.0803 26.813L16.0003 23.0797L20.9203 26.8264C21.9469 27.613 23.3869 26.613 23.0003 25.373L21.0936 19.2264L25.9469 15.7597C27.0003 14.9997 26.4669 13.3464 25.1736 13.3464H19.2403V13.333Z"
        fill="#212121"
      />
    </svg>
  </div>
</template>
