<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="#C9252F"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.0007 3.75C5.83398 3.75 2.27565 6.34167 0.833984 10C2.27565 13.6583 5.83398
    16.25 10.0007 16.25C14.1673 16.25 17.7256 13.6583 19.1673 10C17.7256 6.34167 14.1673 3.75
    10.0007 3.75ZM10.0007 14.1667C7.70065 14.1667 5.83398 12.3 5.83398 10C5.83398 7.7 7.70065
    5.83333 10.0007 5.83333C12.3007 5.83333 14.1673 7.7 14.1673 10C14.1673 12.3 12.3007 14.1667
    10.0007 14.1667ZM10.0007 7.5C8.61732 7.5 7.50065 8.61667 7.50065 10C7.50065 11.3833 8.61732
    12.5 10.0007 12.5C11.384 12.5 12.5007 11.3833 12.5007 10C12.5007 8.61667 11.384 7.5 10.0007
    7.5Z"
    />
  </svg>
</template>
