<template>
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="#444444"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.5013 7.58329H7.58464V10.5C7.58464 10.8208 7.32214 11.0833 7.0013
    11.0833C6.68047 11.0833 6.41797 10.8208 6.41797 10.5V7.58329H3.5013C3.18047
    7.58329 2.91797 7.32079 2.91797 6.99996C2.91797 6.67913 3.18047 6.41663 3.5013
    6.41663H6.41797V3.49996C6.41797 3.17913 6.68047 2.91663 7.0013 2.91663C7.32214
    2.91663 7.58464 3.17913 7.58464 3.49996V6.41663H10.5013C10.8221 6.41663 11.0846
    6.67913 11.0846 6.99996C11.0846 7.32079 10.8221 7.58329 10.5013 7.58329Z"
    />
  </svg>
</template>
